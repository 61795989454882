import React from 'react'
import { Container, Flex, Box, Card, css, Text } from 'theme-ui'
import ListItem from '@solid-ui-components/ListItem'
import Icon from '@solid-ui-components/ContentIcon'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/Educabot/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  items: {
    display: 'none',
      '@media (max-width: 765px)': {
        display: 'block'
      },
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: '#2A205E',
    '@media (max-width: 765px)': {
      fontSize: 30,
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  contentTitle : { 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    '@media (max-width: 765px)': {
      justifyContent: 'flex-start',
    },
  },
  subTitle: { 
    fontSize: '18px'
  },
  card: {
    border: '1px solid #FFB800',
    color: '#FFB800',
    height: '60px'
  },
  contentCard: {
    '@media (max-width: 765px)': {
      display: 'none'
    }
  },
  textContainer: {
    '@media (max-width: 500px)': {
      textAlign: 'center',
    }
  },
  subTitle: { 
    fontSize: '18px'
  },
  card: {
    border: '1px solid #01CEAA',
    color: '#01CEAA',
    height: '45px',
    paddingTop: '9px',
    borderRadius: '9px'
  },
  contentText: {
    flexBasis: `2/5`,
    textAlign: [`left`, 'left', 'center', `left`],
    '@media (max-width: 765px)': {
      pt: '2rem',
    },
    '@media (max-width: 545px)': {
      pt: '3rem',
    }
  },
  listItems: {
    display: 'flex',
    flexWrap: `wrap`,
    maxWidth: 500,
    m: -2,
    mt: 15,
    mb: 15,
    '@media (max-width: 991px)': {
      padding: '20px 0 20px 0',
      margin: 'auto'
    }
  }
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, collection, icon },
  reverse
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? `column-revers` : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`,
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        />
      </Box>

      <Box sx={styles.contentText}>
        <Reveal effect='fadeInDown'>
          <Box sx={styles.contentTitle}>
            <Icon content={icon} mr='2' size='10px' p='2' round />
            <Box sx={styles.textContainer}>
            <ContentText content={text} />
            <Text sx={styles.subTitle}>Los kits incluyen <strong>60 piezas imantadas</strong> como triángulos, cuadrados, hexágonos y rombos, además de un manual para que puedas guiarte en el armado de algunas de nuestras figuras favoritas.</Text>
          </Box>
        </Box>
        </Reveal>
        <Divider space={3}/>
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0],
              '@media (max-width: 500px)': {
                textAlign: 'center'
              }
            })}
          >
            {buttons && (
              <>
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
)


export default WithDefaultContent(HeroBlock01)
